.eor table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.eor td, .eor th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 10px;
    vertical-align: top;
}

.eor tr:nth-child(odd) {
    background-color: #f0f0f0 !important;
}

.eor tr:nth-child(even) {
    background-color: white;
}
