.euka-datatables .datatable-wrapper {
    background: white;
    color: black;
}

.euka-datatables .pagination button.active {
    background: #4DAF50!important;
    color: white;
}

.euka-datatables tr.footer-row{
    font-weight: bold!important;
}

.euka-datatables tr.footer-row td{
    border-top: 2px solid black!important;
}

.euka-datatables .table-search-input {
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    border: 1px solid grey;
    padding: 3px;
    /* border: none; */
}

.euka-datatables .table-records-per-page-select {
    border-radius: 5px;
}

.loan-tables{
    border: 1px solid #3CB534;
    padding: 10px;
    border-radius: 10px;
    font-size: 12px;
}

.euka-datatables .datatable tr.child-table-tr{
    background: white;
}
